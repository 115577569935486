body,
.bg-comment {
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 252, 252, 0.93) 11%,
    rgba(240, 240, 240, 0.7) 45%,
    rgba(240, 240, 240, 0.33) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(11%, rgba(252, 252, 252, 0.93)),
    color-stop(45%, rgba(240, 240, 240, 0.7)),
    color-stop(100%, rgba(240, 240, 240, 0.33))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 252, 252, 0.93) 11%,
    rgba(240, 240, 240, 0.7) 45%,
    rgba(240, 240, 240, 0.33) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 252, 252, 0.93) 11%,
    rgba(240, 240, 240, 0.7) 45%,
    rgba(240, 240, 240, 0.33) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 252, 252, 0.93) 11%,
    rgba(240, 240, 240, 0.7) 45%,
    rgba(240, 240, 240, 0.33) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 252, 252, 0.93) 11%,
    rgba(240, 240, 240, 0.7) 45%,
    rgba(240, 240, 240, 0.33) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f0f0f0', GradientType=1 );
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
    Based on: https://codepen.io/nickelse/pen/YGPJQG
    Influenced by: https://sproutsocial.com/
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

/* #Mega Menu Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.mega-menu {
  display: none;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
}

/* #Hoverable Class Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.hoverable {
  position: static;
}

.hoverable > a:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.hoverable:hover .mega-menu {
  display: block;
}

/* #Toggle Class Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.toggleable > label:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.toggle-input {
  display: none;
}
.toggle-input:not(checked) ~ .mega-menu {
  display: none;
}

.toggle-input:checked ~ .mega-menu {
  display: block;
}

.toggle-input:checked + label {
  color: white;
  background: #2c5282; /*@apply bg-blue-800 */
}

.toggle-input:checked ~ label:after {
  content: "\25B2";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

/*INICIO estilos de cebra para tablas
(comentar estilos actuales, no eliminarlos por referencia historica)*/

table tbody tr:nth-of-type(even) {
  background-color: #f8f8f8;
}

table tbody tr:nth-of-type(1n + 0) {
  border-top: 1pt solid #eee;
  border-bottom: 1pt solid #eee;
}

/* table tbody tr:first-child {
  border-top: 1pt solid #eee;
} */

/* table tbody tr:last-child {
  border-bottom: 1pt solid #eee;
} */

/* table tbody tr td:first-child {
  background-color: #fff;
  border-right: 1pt solid #eee;
  border-top: 1pt solid #eee;
  border-bottom: 1pt solid #eee;
} */

/* table tbody tr td:last-child {
  background-color: fff;
  border-top: 1pt solid #eee;
  border-bottom: 1pt solid #eee;
} */

/*FIN estilos de cebra para tablas*/

/* #Docker Menu
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.appear {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
